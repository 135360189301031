export const getUsersList = (users) => {
  const filtereedData = users.map((el) => {
    const user = {};
    user.name = el.Remittee_name;
    user.country = el.Remittee_country
    user.type = getOccupation(el.registration_type_cd)
    user.bank_name = el.payment_mode_cd;
    user.IFSC = el.bank_ac_no;
    user.remitteeId = el.Remittee_ID;
    user.countryCode = el.Country_code;
    return user
  })
  return filtereedData;
}


const getOccupation = (type) => {
  if (type == 'BUS') {
    const name = 'Business'
    return name;
  }
  if (type == 'IND') {
    const name = 'Individual';
    return name;
  }
}

export const getNationalList = (list) => {
  const filtereedData = list.map((el) => {
    const nationality = {};
    nationality.text = el.nationality;
    nationality.value = el.nationality
    return nationality
  })
  return filtereedData;
}

export const getSourceFunds = (source) => {
  const filtereedData = source.map((el) => {
    const source = {};
    source.text = el.value;
    source.value = el.value;
    return source
  })
  return filtereedData;
}

export const getOccpuationList = (occupation) => {
  const filtereedData = occupation.map((el) => {
    const source = {};
    source.text = el.value;
    source.value = el.value;
    return source
  })
  return filtereedData;
}
export const getCountryFormat = (country) => {
  const filtereedData = country.map((el) => {
    const source = {};
    source.id = el.id
    source.text = el.name;
    source.value = {
      id: el.id,
      code: el.iso3,
      name: el.name,
      currency: el.currency
    };
    return source;
  })
  return filtereedData;
}
export const getCurrencyFormat = (country) => {
  const filtereedData = country.map((el) => {
    const source = {};
    source.id = el.id
    source.text = el.currency;
    source.value = {
      id: el.id,
      code: el.iso3,
      name: el.name,
      currency: el.currency
    };
    return source;
  })
  return filtereedData;
}

export const getCountryKyc = (country) => {
  const filtereedData = country.map((el) => {
    const source = {};
    source.id = el.id
    source.text = el.name;
    source.value = {
      id: el.id,
      code: el.iso2,
      name: el.name,
    };
    return source;
  })
  return filtereedData;
}

export const getStateFormat = (state) => {
  const filtereedData = state.map((el) => {
    const source = {};
    source.id = el.id
    source.text = el.name;
    source.value = {
      id: el.id,
      code: el.state_code,
      name: el.name
    };
    return source;
  })
  return filtereedData;
}

export const getCityFormat = (city) => {
  const filtereedData = city.map((el) => {
    const source = {};
    source.id = el.id
    source.text = el.name;
    source.value = {
      id: el.id,
      name: el.name,
    };
    return source;
  })
  return filtereedData;
}



export const getRelationshipFormatted = (relation) => {
  const filtereedData = relation.map((el) => {
    const relation = {};
    relation.text = el.value;
    relation.value = el.value_code;
    return relation
  })
  return filtereedData;
}

export const getSingupCountry = (country) => {
  const filtereedData = country.map((el) => {
    const source = {};
    source.value = el.iso_alpha2,
      source.text = el.country_name;
    return source;
  })
  return filtereedData;
}


export const getspecifiedDate = (date = new Date, numberofdays = 0) => {
  return new Date(new Date().setDate(date.getDate() - numberofdays));

}



