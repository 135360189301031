<template>
  <div class="homeview">
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
    <div class="page-body">
      <!-- <TitleBar title="" /> -->
      <v-container>
        <v-row class="">
          <v-col cols="12" md="6" lg="6" class="my-2">
            <v-card
              class="pa-2 pb-4 main-card v-card-tile h-100"
              rounded="lg"
              elevation="5"
            >
              <v-row no-gutters>
                <v-icon>mdi-account-circle</v-icon
                ><label class="pt-1 ps-1 m-0 text-color">{{
                  $t("profile_Status")
                }}</label>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-progress-circular
                    :rotate="360"
                    :size="200"
                    :width="25"
                    :value="profile_status"
                    color="green"
                    class="text-h5"
                  >
                    {{ profile_status }}{{ "%" }}
                  </v-progress-circular></v-col
                >
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-list nav dense>
                    <v-list-item class="d-flex justify-center">
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text"
                          x-small
                          fab
                          :color="profileData.personal_info ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.personal_info
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("personalInfo")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text"
                          x-small
                          fab
                          :color="profileData.contact_info ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.contact_info
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("contactInfo")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text"
                          x-small
                          fab
                          :color="profileData.documents ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.documents
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("uploadingDoc")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text"
                          x-small
                          fab
                          :color="profileData.kyc ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.kyc
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("kycApproval")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="my-2">
            <v-card
              class="pa-2 pb-4 main-card v-card-tile h-100"
              rounded="lg"
              elevation="5"
            >
              <v-row no-gutters>
                <v-icon color="#6d6d6d">mdi-lock</v-icon
                ><label class="pt-1 ps-1 m-0 text-color">{{
                  $t("security&verification")
                }}</label>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-progress-circular
                    :rotate="360"
                    :size="200"
                    :width="25"
                    :value="security_status"
                    color="green"
                    class="text-h5"
                  >
                    {{ security_status }}{{ "%" }}
                  </v-progress-circular></v-col
                >
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-list nav dense>
                    <v-list-item class="d-flex justify-center">
                      <v-list-item-icon class="me-4">
                        <v-btn class="white--text" x-small fab color="green">
                          <v-icon> mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("2fa")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn class="white--text" x-small fab color="green">
                          <v-icon> mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">
                        {{ $t("256bit") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn class="white--text" x-small fab color="green">
                          <v-icon> mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">
                        {{ $t("mobile") }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text"
                          x-small
                          fab
                          :color="email_confirmed ? 'green' : 'orange'"
                        >
                          <v-icon>{{
                            email_confirmed
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("email_home")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mb-2">
          <v-col cols="12" md="6" lg="6" class="my-2">
            <loading
              :active="isRecentTrans"
              :is-full-page="false"
              :opacity="0.7"
              color="#ff6b00"
            />

            <v-card
              class="pa-3 pt-0 main-card v-card-tile h-100 recent-trans-mb"
              rounded="lg"
              elevation="5"
            >
              <v-list class="h-inheriate">
                <v-row no-gutters>
                  <label class="pa-2 m-0 text-color">{{
                    $t("recent_transcations")
                  }}</label>
                  <v-icon class="ml-auto" title="More" @click="handleViewMore"
                    >mdi-launch
                  </v-icon>
                </v-row>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="item in recentTransaction"
                    :key="item.id"
                    class="list-template m-0 p-0"
                  >
                    <v-list-item-content class="px-0">
                      <v-row no-gutters class="d-flex justify-space-between">
                        <v-col cols="12" lg="6" md="6" class="d-flex">
                          <div class="me-3">
                            <v-avatar color="info" size="45">
                              <span class="white--text text-h5">{{
                                item.beneficiary_name[0].toUpperCase()
                              }}</span>
                            </v-avatar>
                          </div>
                          <div>
                            <label
                              ><b>{{ item.beneficiary_name }}</b></label
                            >
                            <br />
                            <label class="text--secondary">
                              {{ item.relation_with_remitter }}
                            </label>
                            <!-- <router-link class="link" :to="{ path: '/hello', query: { name: 'Foo' }}"></router-link> -->
                            <a
                              class="text-docoration-none"
                              @click="getTransactionDetials(item)"
                              ><p class="text--orange ellipsis ml-0 mb-0">
                                {{ item.transaction_id.slice(0, 15) }}
                              </p></a
                            >
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          md="5"
                          lg="5"
                          class="d-flex justify-end"
                        >
                          <div>
                            <label class="color-primary"
                              ><b>{{ item.sending_amount }}</b></label
                            ><br />
                            <label class="text--secondary">{{
                              item.send_date
                            }}</label>
                            <p class="text--secondary ellipsis ml-0 mb-0">
                              {{
                                item.status === "Transferred"
                                  ? "In progress"
                                  : item.status
                              }}
                            </p>
                          </div>
                          <div class="ms-1">
                            <v-btn
                              small
                              fab
                              outlined
                              color="primary"
                              @click="handleRepeatTransaction(item.id)"
                              title="Repeat Transaction"
                            >
                              <v-icon>mdi-repeat-variant</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col lg="6" md="6" cols="12" class="my-2">
            <v-card
              class="pa-3 main-card v-card-tile h-100"
              rounded="lg"
              elevation="5"
            >
              <v-row no-gutters>
                <v-col lg="4" sm="12" xs="12">
                  <label class="v-grap-text mt-3 m-1">
                    {{ $t("exchange_rate_home") }}
                  </label>
                </v-col>
                <v-col lg="3" class="d-flex justify-content-end">
                  <country-flag :country="selected_flag" class="mb-0 mt-3" />
                  <v-select
                    :items="country_list"
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="selectedCountry"
                    item-text="text"
                    item-value="value"
                    solo
                    flat
                    class="mt-2 p-0 custome-text custome-slect-prepend"
                    style="width: 70px; height: 33px; margin-bottom: 15px"
                  >
                    <template v-slot:item="data">
                      <template class="border">
                        <country-flag
                          :country="data.item.value.iso2"
                          class="m-0"
                        />
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.text + ' ' + data.item.value.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
                <v-col lg="5" class="text-end">
                  <v-btn
                    @click.prevent="onClick1Week(7)"
                    :class="`${
                      no_of_days === 7 ? 'active-btn' : ''
                    } v-grap-text v-grap-v-btn p-0`"
                    id="one week"
                    >1W</v-btn
                  >
                  <v-btn
                    @click.prevent="onClick1Week(14)"
                    :class="`${
                      no_of_days === 14 ? 'active-btn' : ''
                    } v-grap-text v-grap-v-btn p-0`"
                    id="one week"
                    >2W</v-btn
                  >
                  <v-btn
                    @click.prevent="onClick1Week(30)"
                    :class="`${
                      no_of_days === 30 ? 'active-btn' : ''
                    } v-grap-text v-grap-v-btn p-0`"
                    id="one week"
                    >30D</v-btn
                  >
                </v-col>
              </v-row>
              <apexchart
                type="area"
                height="250"
                ref="chart"
                :key="isKey"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-6">
          <v-col cols="12">
            <v-card
              class="pa-2 main-card v-card-tile h-100"
              rounded="lg"
              elevation="5"
            >
              <v-row no-gutters>
                <v-icon>mdi-account-circle</v-icon
                ><label class="pt-1 ps-1 m-0 text-color">{{
                  $t("accountSumary")
                }}</label>
              </v-row>
              <v-row class="d-flex justify-space-between pa-5">
                <v-col cols="12" md="4">
                  <v-card class="mt-4" elevation="4">
                    <div class="d-flex">
                      <v-sheet
                        class="v-sheet--offset ms-4 pa-4 mx-auto d-flex justify-center"
                        color="cyan"
                        elevation="12"
                        width="70px"
                        rounded
                        height="70px"
                      >
                        <v-icon color="white" large> mdi-cash-multiple </v-icon>
                      </v-sheet>
                      <div class="me-4 mt-2">
                        <div class="text-right text-color font-size">
                          {{ $t("sendAmount") }}
                        </div>
                        <div class="text-right text-h5 font-weight-bold">
                          <a href="/transactionsummary" link>{{
                            this.dashDetails.total_amount || 0
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card class="mt-4" elevation="4">
                    <div class="d-flex">
                      <v-sheet
                        class="v-sheet--offset ms-4 pa-4 mx-auto d-flex justify-center"
                        color="info"
                        elevation="12"
                        rounded
                        width="70px"
                        height="70px"
                      >
                        <v-icon color="white" large>
                          mdi-account-arrow-left
                        </v-icon>
                      </v-sheet>
                      <div class="me-4 mt-2">
                        <div class="text-right text-color font-size">
                          {{ $t("numberTransaction") }}
                        </div>
                        <div class="text-right text-h5 font-weight-bold">
                          <a href="/transactionsummary" link>{{
                            this.dashDetails.no_of_transactions || 0
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card class="mt-4" elevation="4">
                    <div class="d-flex">
                      <v-sheet
                        class="v-sheet--offset ms-4 pa-2 mx-auto d-flex justify-center"
                        color="warning"
                        elevation="12"
                        width="70px"
                        rounded
                        height="70px"
                      >
                        <v-icon color="white" large>
                          mdi-account-group-outline
                        </v-icon>
                      </v-sheet>
                      <div class="me-4 mt-2">
                        <div class="text-right text-color font-size">
                          {{ $t("numberBenficary") }}
                        </div>
                        <div class="text-right text-h5 font-weight-bold">
                          <a href="/beneficiarysummary" link>{{
                            this.dashDetails.no_of_beneficiaries || 0
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-row justify="center">
        <v-dialog v-model="isNotify" persistent max-width="400" elevation="12">
          <v-card>
            <v-card-title class="color-primary font-weight-bold h3 pa-2 m-0">
              {{ $t("qrate") }}
            </v-card-title>
            <v-divider class="p-0 m-0"></v-divider>
            <v-card-text class="pa-5 text-h6">
              {{ showNotificationMsg }}
            </v-card-text>
            <v-divider class="p-0 m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeNotify">
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { getspecifiedDate } from "@/utils/UsersList.js";
import axios from "axios";
import { mobilebreakdown, webbreakdown } from "../utils/breakpoints";
export default {
  name: "HomeView",
  components: {
    Loading,
  },
  data() {
    return {
      loader: false,
      mobilebreakdown,
      webbreakdown,
      navigatetoPage: "",
      isNotify: false,
      email_confirmed: false,
      showNotificationMsg: "",
      profile_status: 0,
      security_status: 75,
      profileData: {},
      isRecentTrans: false,
      no_of_days: 30,
      isKey: 1,
      chartOptions: {
        chart: {
          title: "Exchange Rates",
          id: "exchange-rate",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        colors: ["#ff6b00"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
          showNullDataPoints: true,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${parseFloat(value).toFixed(2)} ${this.target_currency}`;
            },
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 7,
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy",
          },
        },
      },

      series: [
        {
          name: "Rate",
        },
      ],
      list_items: [
        "Bank Transfer",
        "Cash Pick-Up",
        "Home Delivery",
        "e-Wallet",
      ],
      recentTransaction: [],
      // country_list: [{ text: "INR", value: { code: "IN", currency: "INR" } }],
      selectedCountry: {},
      target_currency: "INR",
      base_currency: "MYR",
      dashDetails: {},
      selected_flag: "IN",
      country_list: [],
    };
  },
  created() {
    this.GetNotification();
  },
  watch: {
    mobilebreakdown(newval) {
      // alert(newval);
      console.log(newval);
    },
    selectedCountry(val) {
      this.selected_flag = val.iso2;
      this.target_currency = val.currency;
      this.getExchangeRate(val.iso3);
    },
  },
  mounted() {
    this.getProfile();
    this.getCountryList();
    this.getDashboardDetails();
   
    this.getRecentTransaction();
  },
  methods: {
    getProfile() {
      this.$store
        .dispatch("Authentication/getProfile")
        .then((res) => {
          if (Array.isArray(res.data.data)) {
            this.profiledata = res.data.data;
            if (this.profiledata[0].is_email_confirmation) {
              this.security_status = 100;
              this.email_confirmed = true;
            } else {
              this.security_status = 75;
              this.email_confirmed = false;
            }
          }
        })
        .catch((err) => console.error(err));
    },
    async getCountryList() {
      let responseData = await this.$store.dispatch(
        "Authentication/getBeneficiaryCountryList"
      );
      responseData.data.data.map((i) => {
        this.country_list.push({
          text: i.currency_code,
          value: {
            iso2: i.iso_alpha2,
            iso3: i.iso_alpha3,
            name: i.country_name,
            currency: i.currency_code,
          },
        });
      });
      this.selectedCountry = {
        iso2: this.country_list[0].value.iso2,
        iso3: this.country_list[0].value.iso3,
        name: this.country_list[0].value.name,
        currency: this.country_list[0].value.currency,
      };
      this.getExchangeRate(this.selectedCountry.iso3);
    },
    closeNotify() {
      console.log(this.navigatetoPage);
      this.isNotify = false;
      this.$router.push({ name: this.navigatetoPage });
      this.updateNotification();
    },
    async GetNotification() {
      const user_type = sessionStorage.getItem("user_type");
      let responseData = await this.$store.dispatch(
        "Transactional/getNotificationStatus"
      );
      let statusDetails = await this.$store.dispatch(
        "Transactional/getProfileData"
      );
      console.log("statusDetails", statusDetails.data[0]);
      let kyc = statusDetails.data[0].kyc;
      let registered = statusDetails.data[0].personal_info;
      console.log("kyc", kyc);
      console.log("registered", registered);
      let notification = responseData.data[0].notification;
      sessionStorage.setItem("ActiveNotify", notification);
      if (!registered && notification) {
        this.isNotify = true;
        this.showNotificationMsg =
          "Seems you are not yet registered with us.Please complete the registration process by clicking 'Continue'.";

        if (user_type === "BUS") {
          this.navigatetoPage = "BusUserInfo";
        } else {
          this.navigatetoPage = "CreateUserMain";
        }
      }
      if (registered && !kyc && notification) {
        this.isNotify = true;
        this.showNotificationMsg =
          "Seems you are not yet completed the KYC process.Please complete the KYC by clicking 'Continue'.";
        this.navigatetoPage = "KYC";
      }
    },
    async getTransactionDetials(data) {
      this.$router.push({
        name: "TransactionSummary", //use name for router push
        params: { data },
      });
    },
    async getDashboardDetails() {
      this.loader = true;
      let responseData = await Promise.all([
        this.$store.dispatch("Transactional/getDashboardDetails"),
        this.$store.dispatch("Transactional/getProfileData"),
      ]);
      console.log("dashDetails", responseData[0].status_code);
      if (responseData[0].status_code === 200) {
        this.loader = false;
        this.dashDetails = responseData[0].data[0];
        this.profileData = responseData[1].data[0];
        console.log("dashDetails", this.dashDetails);
        console.log("profileData", this.profileData);
        let value = 0;
        for (let status in this.profileData) {
          if (this.profileData[`${status}`]) {
            value += 1;
            this.profile_status = value * 25;
          }
        }
      } else {
        this.loader = false;
      }

      console.log(this.profile_status);
    },
    handleViewMore() {
      this.$router.push("/transactionSummary");
    },
    handleRepeatTransaction(id) {
      this.$router.push(`/transaction/${id}`);
    },
    async getRecentTransaction() {
      // this.isRecentTrans = true;
      let responseData = await this.$store.dispatch(
        "Transactional/getRecentTransactions"
      );
      // this.isRecentTrans = false;
      console.log("responseData.data", responseData);
      if (responseData.status_code === 200) {
        this.recentTransaction = responseData.data;
      }
    },
    onClick1Week(val) {
      this.no_of_days = val;
      this.getExchangeRate(this.selectedCountry.iso3);
    },
    async getExchangeRate(country_code) {
      const token = sessionStorage.getItem("access_token");
      try {
        let responseData = await axios.get(
          `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-graph-data?country=${country_code}&no_of_days=${this.no_of_days}`, //fix me
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              accept: "application/json",
            },
          }
        );
        console.log("getExchangeRate", responseData);
        if (responseData.data.status_code === 200) {
          console.log("getExchangeRate22", responseData.data.data);
          this.exchange_rate = responseData.data.data;
          this.isKey = getspecifiedDate(new Date(), this.no_of_days).getTime();
          this.series[0].data =
            responseData.data.data.length > 0
              ? responseData.data.data.map((e) => {
                  return [new Date(e.created_date).getTime(), e.txn_rate];
                })
              : [];

          console.log("this.series[0].data", this.series[0].data);
        }
      } catch (e) {
        return e;
      }
    },
    async updateNotification() {
      const token = sessionStorage.getItem("access_token");
      try {
        let responseData = await axios.put(
          `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/update-notification?notification=false`,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(responseData);
        const notification = responseData.data[0].notification;
        sessionStorage.setItem("ActiveNotify", notification);
      } catch (e) {
        return e;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/global.scss";
.label-text {
  font-size: 10px !important;
}
.v-auto-complete {
  max-width: 50px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: $lightgrey !important;
}
.list-template {
  border-top: 1px solid $lightgrey;
  padding: 0.2rem 1rem;
}
.v-text-field__details {
  height: 0px !important;
}
.border-grey {
  border: 2px solid $gainsboro;
}
.normal-flag {
  margin: 0px;
}
.v-text-field {
  padding-top: 0px;
  margin: 0px 6px;
}
.v-grap-v-btn {
  box-shadow: none !important;
  background-color: transparent !important;
  min-width: 20px !important ;
  margin: 3px;
}
.v-grap-text {
  font-size: 14px !important;
  color: $lightgrey !important;
}

.v-home-subcard-2 {
  background-color: rgb(52, 52, 192) !important;
  padding: 20px;
  color: #efefef !important;
  min-height: 120px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.v-home-subcard-header-2 {
  background-color: rgb(52, 52, 192) !important;
  padding: 2px;
  color: #efefef !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px !important;
  text-align: center;
}
.v-home-subcard-main {
  border: 1px solid rgb(52, 52, 192);
  padding-bottom: 10px;
}

.v-home-subcard-header-1 {
  background-color: #12be57 !important;
  padding: 2px;
  color: #efefef !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px !important;
  text-align: center;
}
.v-home-subcard-main-1 {
  border: 1px solid #12be57;
  padding-bottom: 10px;
  flex-direction: row;
}
.v-home-subcard-1 {
  background-color: #12be57 !important;
  padding: 20px;
  color: #efefef !important;
  text-align: center;
}
.v-sheet--offset {
  top: -15px;
  width: 80px;
  height: 80px;
  position: relative;
}
.text-color {
  color: #6d6d6d;
}
.font-size {
  font-size: 14px;
}
.anchor {
  color: orange;
}
</style>
